body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special-label {
  visibility: hidden;
}

.react-tel-input .form-control {
  background-color: #ffffff;
  color: #111;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 50px;
  font-family: NexaRegular, sans-serif;
  transition: none !important;
  width: 100% !important;
}

.react-tel-input .form-control:hover{
  border-color: #7b92ec !important;
  transition: none !important;
}

.react-tel-input .form-control:focus{
  border-color: #7b92ec !important;
  transition: none !important;
}
.custom-canvas{
  width: 100% !important;
  height: 100% !important;
}



::-webkit-scrollbar{
  width: 12px;
  background-color: rgb(239, 239, 239);
  opacity: 0;
  /*box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);*/
}
::-webkit-scrollbar-thumb{
  /*box-shadow: 0 4px 6px rgba(3, 54, 100, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);*/
  /*background: #DA22FF;  !* fallback for old browsers *!*/
  background: #303030;
  border: 2px solid rgb(239, 239, 239);
  border-radius: 6px;
  width: 12px;
}
::-webkit-scrollbar-thumb:hover{
  /*background: #DA22FF;  !* fallback for old browsers *!*/
  background: #303030;
  width: 10px;
  transition: all 1s ease;
}
